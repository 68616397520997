/* eslint-disable no-undef */
const settings = {
  baseURL: process.env.baseURL,
  supportedLocales: process.env.supportedLocales.map((locale) => {
    return locale.toUpperCase();
  }),
  allLocales: process.env.allLocales.map((locale) => {
    return locale.toUpperCase();
  }),
  app: process.env.app,
  version: process.env.version,
  pageSize: parseInt(process.env.pageSize, 10),
  baseScriptURL: `${ process.env.baseScriptUrl }/${ process.env.version }/`,
  target: process.env.mode,
  logLevel: process.env.logLevel,
  logWriter: process.env.logWriter,
  logFormatter: process.env.logFormatter,
  logMaxEntries: process.env.logMaxEntries,
  buildId: process.env.buildId,
  assetHost: process.env.assetHost
};

const TARGETS = {
  DEV: 'development',
  PROD: 'production'
};

const generateInvalidateCacheQuery = ({
  target,
  buildId
} = {}) => {
  if (target === TARGETS.PROD) {
    return `?_${ buildId }`;
  } else if (target === TARGETS.DEV) {
    return `?_${ (new Date()).getTime() }`;
  }

  return '';
};

// Generate a unique identifier for this session
// Well accepted way of generating a GUID using simple javascript to support all browsers
// Details found here https://stackoverflow.com/a/2117523
const uuid = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) => {
    return (c ^ (window.crypto || window.msCrypto).getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16);
  });
};

const image_source = (src) => {
  const {
    assetHost,
    version
  } = settings;

  let sourceString = '';
  if (assetHost && (assetHost.length > 0)) {
    sourceString = assetHost + '/';
  }

  if (version && (version.length > 0)) {
    sourceString += version + '/';
  }

  sourceString += src;

  return sourceString;
};

module.exports = {
  settings,
  TARGETS,
  uuid,
  invalidateCacheQuery: generateInvalidateCacheQuery(settings),
  instanceId: uuid(),
  image_source
};
