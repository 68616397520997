const LoadTimeLogController = require('LoadTimeLogController');
LoadTimeLogController.start('STARTJS');

require('@common/configs/bluebird/bluebird-config');

require('./main.less');

import('./bootstrap')
  .then(({ default: BaseApp }) => {
    LoadTimeLogController.stop('STARTJS');

    window.apps = new BaseApp();
    window.apps.start();
  });

