const {
  settings,
  TARGETS
} = require('env');

const Promise = require('bluebird');

const promiseDebugMode = settings.target === TARGETS.DEV ? true : 0;

Promise.config({
  warnings: promiseDebugMode,
  longStackTraces: promiseDebugMode,
  cancellation: 0,
  monitoring: 0
});

window.Promise = Promise;
